import React from "react";
import cn from "classnames";
import { Portal } from "react-portal";
import useBodyScrollLock from "@/plugins/useBodyScrollLock";
import * as css from "./tickets-popup.modules.scss";

type TicketsPopupProps = {
  isOpen: boolean;
};
const TicketsPopup: React.FC<TicketsPopupProps> = ({ isOpen, children }) => {
  const [setLock, windowScrollRef] = useBodyScrollLock();

  React.useEffect(() => {
    setLock(isOpen);

    return () => {
      setLock(false);
    };
  }, [isOpen]);

  return (
    <Portal key="tickets-portal">
      <div
        className={cn("tablet:hidden", css.ticketsWindow, {
          [css.isOpen]: isOpen,
        })}
      >
        <div
          className={cn("px-4 pb-16 pt-4", css.ticketsWindowInner)}
          ref={windowScrollRef}
        >
          {children}
        </div>
      </div>
    </Portal>
  );
};

export default TicketsPopup;
