import React from "react";
import { graphql } from "gatsby";
import SeoRu from "@/components/seo/index.ru";
import SeoEn from "@/components/seo/index.en";
import ShowPage from "@/components/pages/show";

function ShowTemplate(props) {
  const { data, pageContext } = props;
  const { locale } = pageContext;

  return (
    <>
      {locale === "ru-RU" && (
        <SeoRu
          title={data.show?.title}
          description={data?.show?.ogDescription?.ogDescription}
          ogImage={data?.show?.ogImage}
        />
      )}
      {locale === "en-US" && (
        <SeoEn
          title={data.show?.title}
          description={data?.show?.ogDescription?.ogDescription}
          ogImage={data?.show?.ogImage}
        />
      )}
      <ShowPage data={data} />
    </>
  );
}

export default ShowTemplate;

export const pageQuery = graphql`
  query($locale: String!, $langKey: String!, $slug: String!) {
    siteSettings: contentfulSiteSettings(node_locale: { eq: $locale }) {
      covidNotification {
        childMarkdownRemark {
          html
        }
      }
    }
    layoutHome: contentfulLayoutHome(node_locale: { eq: $locale }) {
      pageMode
    }
    program: contentfulLayoutProgram(node_locale: { eq: $locale }) {
      soldOutText
      shows {
        cover_listing_image {
          fluid(maxWidth: 700, maxHeight: 370, resizingBehavior: FILL) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        author
        title
        slug
      }
    }
    show: contentfulContentShow(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      title
      author
      age_limit
      soldOut
      allTicketsUrl {
        allTicketsUrl
      }
      duration
      id
      slug
      youtubeVimeoIframe
      cover_images {
        fluid(maxWidth: 1600) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      shortDescription {
        childMarkdownRemark {
          html
        }
      }
      side_notes {
        childMarkdownRemark {
          html
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      ticketsJson {
        name
        tickets {
          address
          addressEn
          venue
          venueEn
          cityShortCode
          cityShortCodeEn
          tickets {
            datetime(formatString: "D MMM,|ddd|HH:mm", locale: $langKey)
            datetimeISO
            radarioId
            url
            soldOut
          }
        }
      }
      partnersBlock {
        images {
          fluid(maxWidth: 360, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      reviews {
        author
        sourceName
        text {
          childMarkdownRemark {
            html
          }
        }
      }
      ogDescription {
        ogDescription
      }
      ogImage {
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
      }
    }
  }
`;
