// extracted by mini-css-extract-plugin
export var sideNotes = "show-modules--sideNotes--tgQ4h";
export var quoteIcon = "show-modules--quoteIcon--29sRy";
export var coverPlaceholder = "show-modules--coverPlaceholder--G13B1";
export var iframeContainer = "show-modules--iframeContainer--1qQ80";
export var programCarousel = "show-modules--programCarousel--1QUgt";
export var programCarouselItem = "show-modules--programCarouselItem--GVyjG";
export var ticketsBtn = "show-modules--ticketsBtn--L1k2x";
export var fixedTicketsButton = "show-modules--fixedTicketsButton--aVjyK";
export var fixedTicketsButtonVisible = "show-modules--fixedTicketsButtonVisible--3QmXx";
export var secondTierStickyContainer = "show-modules--secondTierStickyContainer--1sX8a";
export var carouselContainsIframeLastSlide = "show-modules--carouselContainsIframeLastSlide--33m7H";