import React from "react";
import PageTitle from "@/components/kit/page-title";
import * as styleConstants from "@/constants/style";
import Button from "@/components/kit/button";
import Carousel from "@/components/carousel";
import Img from "gatsby-image";
import cn from "classnames";
import { externalLinkProps } from "@/utils";
import TicketBubble from "@/components/ticket-bubble";
import ProgramCarousel from "./program-carousel";
import { HOME_PAGE_OFFSEASON_MODE, HOME_PAGE_SEASON_MODE } from "@/constants";
import TicketsPopup from "./tickets-popup";
import { ShowProps } from "./types";
import ClientOnly from "@/utils/ClientOnly";
import { useStateValue } from "@/store";
import { Portal } from "react-portal";
import QuoteIcon from "@/assets/icons/quote.inline.svg";
import Wysiwyg from "@/components/kit/wysiwyg";
import { useI18n } from "@/plugins/i18n";
import * as css from "./show.modules.scss";

const CAROUSEL_ASPECT_RATIO = 833 / 572;

export type ShowPageProps = {
  data: {
    show: ShowProps;
    program: {
      soldOutText: string;
      shows: ShowProps[];
    };
    layoutHome: {
      pageMode: string;
    };
    siteSettings?: {
      covidNotification?: {
        childMarkdownRemark?: {
          html?: string;
        };
      };
    };
  };
};
const ShowPage: React.FC<ShowPageProps> = ({ data }) => {
  const [{ locale }] = useStateValue();
  const { t } = useI18n();
  const { show, program, layoutHome, siteSettings } = data;
  const {
    age_limit,
    duration,
    shortDescription,
    allTicketsUrl,
    youtubeVimeoIframe,
    cover_images,
    ticketsJson,
    side_notes,
    description,
    reviews,
    partnersBlock,
    soldOut,
  } = show;

  const covidNotificationHtml =
    siteSettings?.covidNotification?.childMarkdownRemark?.html;

  const { soldOutText } = program;

  const closeText = t("close");
  const isOffseason = layoutHome.pageMode === HOME_PAGE_OFFSEASON_MODE;
  const isSeason = layoutHome.pageMode === HOME_PAGE_SEASON_MODE;

  const ticketsBtnText = isSeason ? t("tickets") : t("schedule");

  const ticketGroups = ticketsJson?.tickets;
  const carouselBlock = Array.isArray(cover_images) && (
    <div>
      <Carousel
        className={cn({
          [css.carouselContainsIframeLastSlide]: !!youtubeVimeoIframe,
        })}
        hasButtons={!youtubeVimeoIframe}
      >
        {cover_images.map(({ fluid }, i) => (
          <Img
            key={i}
            fluid={{ ...fluid, aspectRatio: CAROUSEL_ASPECT_RATIO }}
            loading={i === 0 ? "eager" : "lazy"}
          />
        ))}
        {youtubeVimeoIframe ? (
          <div className="w-full">
            <div className="bg-black w-full h-full flex items-center">
              <div className={css.iframeContainer}>
                <iframe
                  src={youtubeVimeoIframe}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  allowFullScreen
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>
        ) : null}
      </Carousel>
    </div>
  );
  const ticketGroupsBlock =
    Array.isArray(ticketGroups) &&
    ticketGroups
      .map((group, i) =>
        Array.isArray(group.tickets) && group.tickets.length > 0 ? (
          <div
            key={i}
            className={cn({
              "mt-0": i === 0,
              "mt-4": i > 0,
              "desktop1440:border-b-3 border-b-2": i < ticketGroups.length - 1,
            })}
          >
            {locale === "ru" && group.venue && (
              <div className="uppercase text-title-2">{group.venue}</div>
            )}
            {locale === "en" && group.venue && (
              <div className="uppercase text-title-2">{group.venueEn}</div>
            )}
            {locale === "ru" && group.address && (
              <div className="mt-2">{group.address}</div>
            )}
            {locale === "en" && group.address && (
              <div className="mt-2">{group.addressEn}</div>
            )}

            {Array.isArray(group.tickets) && group.tickets.length > 0 && (
              <div className="mt-3 mb-5 desktop1440:mb-7 desktop1440:mt-4 flex flex-wrap">
                {group.tickets.map((ticket, j) => (
                  <TicketBubble
                    key={j}
                    className="w-1/3 tablet:w-3/7 desktop:w-1/3"
                    radarioId={ticket.radarioId}
                    url={ticket.url}
                    datetimeStr={ticket.datetime}
                    datetimeISO={ticket.datetimeISO}
                    soldOut={ticket.soldOut}
                    canBuy={isSeason}
                  />
                ))}
              </div>
            )}
          </div>
        ) : null
      )
      .filter(Boolean);

  const hasTicketGroups =
    Array.isArray(ticketGroupsBlock) && ticketGroupsBlock.length > 0;

  const [isOpen, setOpen] = React.useState(false);
  const toggleWindow = () => setOpen(!isOpen);

  const [isMounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <>
      <div className="mb-6 tablet:mb-10 desktop:mb-13 desktop1280:mb-19 desktop1440:mb-22 desktop:pt-10 desktop1280:pt-12 desktop1440:pt-14">
        <div
          className={cn(
            styleConstants.container,
            "tablet:hidden flex flex-col mt-6 text-title-1"
          )}
        >
          {duration && <div className={"uppercase"}>{duration}</div>}
          {age_limit && <div className={"uppercase"}>{age_limit}</div>}
        </div>

        <PageTitle border={false}>
          {show.author && (
            <>
              {show.author} <br />
            </>
          )}
          <span className={"font-normal"}>{show.title}</span>
        </PageTitle>
        <div
          className={cn(
            styleConstants.container,
            "tablet:flex mb-6 desktop:mb-7 desktop1280:mb-9 desktop1440:mb-11"
          )}
        >
          <div className="tablet:hidden mb-10">{carouselBlock}</div>
          <div
            className={
              "hidden tablet:block tablet:w-7/24 desktop:w-1/4 desktop:flex flex-col text-title-1"
            }
          >
            {duration && <div className={"uppercase"}>{duration}</div>}
            {age_limit && <div className={"uppercase"}>{age_limit}</div>}
          </div>
          <div className={"hidden tablet:block w-1/24"}></div>
          {shortDescription && (
            <Wysiwyg
              className={
                "tablet:w-2/3 desktop:w-17/24 uppercase pb-6 tablet:pb-0 border-b-2 tablet:border-b-0 text-title-1"
              }
            >
              {shortDescription.childMarkdownRemark.html}
            </Wysiwyg>
          )}
        </div>

        <div className={cn("hidden tablet:block", styleConstants.container)}>
          <div
            className={cn(
              "flex",
              "desktop1440:border-b-3 border-b-2 desktop1440:border-t-3 border-t-2"
            )}
          >
            <div
              className={
                "tablet:w-7/24 desktop:w-1/4 tablet:py-6 desktop:py-7 desktop1280:py-9 desktop1440:py-10"
              }
            >
              {covidNotificationHtml && (
                <Wysiwyg className="border p-4 mb-4">
                  {covidNotificationHtml}
                </Wysiwyg>
              )}

              {(allTicketsUrl || soldOut) &&
                (soldOut ? (
                  <Button
                    is={"button"}
                    className={"w-full mb-5"}
                    variant={soldOut ? "strikethrough" : "contained"}
                    disabled={soldOut}
                    {...externalLinkProps}
                  >
                    {soldOutText}
                  </Button>
                ) : (
                  <Button
                    is={"a"}
                    href={allTicketsUrl.allTicketsUrl}
                    className={"w-full mb-5"}
                    variant={"contained"}
                    {...externalLinkProps}
                  >
                    {soldOut ? soldOutText : ticketsBtnText}
                  </Button>
                ))}

              {ticketGroupsBlock}
            </div>
            <div className={"hidden tablet:block w-1/24"}></div>
            <div
              className={cn(
                "hidden tablet:block  tablet:w-2/3 desktop:w-17/24 uppercase"
              )}
            >
              <div className="tablet:sticky top-0 tablet:py-6 desktop:py-7 desktop1280:py-9 desktop1440:py-10">
                {carouselBlock}
              </div>
            </div>
          </div>
        </div>

        <div className={cn(styleConstants.container)}>
          <div
            className={cn(
              "flex flex-col-reverse tablet:flex-row",
              "desktop1440:border-b-3 border-b-2"
            )}
          >
            <div className="tablet:w-7/24 desktop:w-1/4 tablet:py-6 desktop:py-7 desktop1280:py-9 desktop1440:py-10">
              {side_notes && (
                <div
                  className={cn(css.sideNotes, "hidden tablet:block")}
                  dangerouslySetInnerHTML={{
                    __html: side_notes.childMarkdownRemark.html,
                  }}
                />
              )}
            </div>
            <div className={"hidden tablet:block w-1/24"} />
            <div className="tablet:w-2/3 desktop:w-17/24">
              <div
                className={cn(
                  css.secondTierStickyContainer,
                  "tablet:sticky tablet:py-6 desktop:py-7 desktop1280:py-9 desktop1440:py-10"
                )}
              >
                <div className="border-b-2 tablet:border-b-0">
                  {description && (
                    <Wysiwyg className="text-big pb-6 tablet:pb-0 mb-6 tablet:mb-8 desktop:mb-12 desktop1280:mb-16 desktop1440:mb-18">
                      {description.childMarkdownRemark.html}
                    </Wysiwyg>
                  )}

                  {Array.isArray(partnersBlock?.images) && (
                    <div
                      className={cn(
                        "flex flex-wrap items-center desktop1440:pb-18 desktop1280:pb-16 desktop:pb-13 tablet:pb-9 pb-6"
                      )}
                    >
                      {partnersBlock.images.map((img, i) => {
                        return (
                          <Img
                            key={i}
                            className="w-1/2 tablet:w-1/5 tablet:mr-6 mb-6 desktop:mr-8 desktop:mb-8"
                            {...img}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>

                {side_notes && (
                  <div
                    className={cn(css.sideNotes, "tablet:hidden mt-4 mb-8")}
                    dangerouslySetInnerHTML={{
                      __html: side_notes.childMarkdownRemark.html,
                    }}
                  />
                )}

                {Array.isArray(reviews) &&
                  reviews.map(({ text, author, sourceName }, i) => {
                    return (
                      <div
                        key={i}
                        className={cn(
                          "pt-7 pb-8 desktop:pt-12 desktop:pb-13 desktop1280:pt-13 desktop1440:pt-16 desktop1280:pb-18",
                          "border-t-2 desktop1440:border-t-3",
                          {
                            "border-b-2 tablet:border-b-0":
                              i === reviews.length - 1,
                          }
                        )}
                      >
                        <QuoteIcon className={css.quoteIcon} />
                        {text && (
                          <div
                            className="mt-3 desktop:mt-4 desktop1280:mt-5 desktop1440:mt-6"
                            dangerouslySetInnerHTML={{
                              __html: text.childMarkdownRemark.html,
                            }}
                          />
                        )}
                        <div className="text-right tablet:text-left tablet:w-3/7 desktop:w-3/8 ml-auto mr-0 mt-5 desktop:mt-6 desktop1280:mt-7 desktop1440:mt-8">
                          {author && (
                            <div className="uppercase text-title-2">
                              {author}
                            </div>
                          )}
                          {sourceName && (
                            <div className="uppercase font-bold text-title-2">
                              {sourceName}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>

        <ClientOnly>
          {!isOffseason && Array.isArray(program?.shows) && (
            <div className="py-6 desktop:py-7 desktop1280:py-9 desktop1440:py-10">
              <ProgramCarousel items={program?.shows} highlight={show.slug} />
            </div>
          )}
        </ClientOnly>
      </div>
      {hasTicketGroups && (
        <Portal>
          <div
            key="tickets-button"
            className={cn(
              "tablet:hidden fixed bottom-0 left-0 w-full bg-black pb-3 text-white z-20",
              css.fixedTicketsButton,
              { [css.fixedTicketsButtonVisible]: isMounted }
            )}
          >
            <button
              className={cn(css.ticketsBtn, "uppercase text-center w-full")}
              onClick={toggleWindow}
            >
              {isOpen ? closeText : ticketsBtnText}
            </button>
          </div>
        </Portal>
      )}
      {hasTicketGroups && (
        <TicketsPopup isOpen={isOpen}>
          {covidNotificationHtml && (
            <Wysiwyg className="border p-4 mb-4">
              {covidNotificationHtml}
            </Wysiwyg>
          )}

          {ticketGroupsBlock}
        </TicketsPopup>
      )}
    </>
  );
};

export default ShowPage;
