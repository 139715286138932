import React from "react";
import { ShowProps } from "./types";
import { useRoute } from "@/utils";
import useMedia from "@/utils/useMedia";
import Img from "gatsby-image";
import NukaCarousel from "nuka-carousel";
import cn from "classnames";
import * as css from "./show.modules.scss";
import { AniLink } from "@/components/global-navigation";

type ProgramCarouselProps = {
  items: Pick<ShowProps, "title" | "author" | "slug" | "cover_listing_image">[];
  highlight?: string;
};

const ProgramCarousel: React.FC<ProgramCarouselProps> = ({
  items,
  highlight,
}) => {
  const route = useRoute();
  const slidesToShow = useMedia(["(min-width: 768px)"], [3.5], 1.5);

  const [slideIndex, setSlideIndex] = React.useState(0);

  const [itemsCurrentSlugFirst] = React.useState(() => {
    const ind = items.findIndex(({ slug }) => slug === highlight);
    return [
      items[ind],
      ...items.slice(0, ind),
      ...items.slice(ind + 1, items.length),
    ];
  });

  return (
    <div className={cn("overflow-hidden", css.programCarousel)}>
      <div className="max-w-desktop1440 mx-auto">
        <NukaCarousel
          slideIndex={slideIndex}
          afterSlide={(slideIndex) => setSlideIndex(slideIndex)}
          className={
            "outline-none pl-4 tablet:pl-0 pr-4 tablet:pr-5 desktop:pr-9 desktop1280:pr-10"
          }
          scrollMode={"remainder"}
          edgeEasing={"easeSinOut"}
          slidesToShow={slidesToShow}
          frameOverflow="visible"
          autoplay={false}
          renderBottomCenterControls={() => null}
          renderBottomLeftControls={() => null}
          renderCenterLeftControls={({ previousSlide, currentSlide }) => (
            <button
              className="hidden desktop:block"
              style={{
                width: "100%",
                height: "100%",
                cursor: "url(/images/arrow-left.png) 0 22, auto",
              }}
              onClick={previousSlide}
              disabled={currentSlide === 0}
            />
          )}
          renderCenterRightControls={({
            nextSlide,
            currentSlide,
            slideCount,
          }) => (
            <button
              className="hidden desktop:block"
              style={{
                width: "100%",
                height: "100%",
                cursor: "url(/images/arrow-right.png) 57 22, auto",
              }}
              onClick={nextSlide}
              disabled={currentSlide === slideCount - 1}
            />
          )}
          getControlsContainerStyles={(key) => {
            switch (key) {
              case "CenterLeft":
                return {
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100px",
                  height: "72%",
                  transform: "none !importnat",
                };
              case "CenterRight":
                return {
                  position: "absolute",
                  top: 0,
                  right: 0,
                  width: "100px",
                  height: "72%",
                  transform: "none !importnat",
                };
            }
          }}
        >
          {itemsCurrentSlugFirst.map(
            ({ title, author, slug, cover_listing_image }, i) => {
              return (
                <AniLink
                  to={route("home", slug)}
                  key={slug}
                  className={cn(
                    css.programCarouselItem,
                    "outline-none block",
                    "tablet:pl-5 desktop:pl-9 desktop1280:pl-10",
                    {
                      "pl-4": i > 0,
                      "is-current": slug === highlight,
                    }
                  )}
                >
                  {cover_listing_image ? (
                    <Img {...cover_listing_image} />
                  ) : (
                    <div className={css.coverPlaceholder} />
                  )}

                  <div
                    className={cn(
                      "uppercase text-title-2 mt-3 desktop1280:mt-4",
                      {
                        "font-bold": slug === highlight,
                      }
                    )}
                    dangerouslySetInnerHTML={{
                      __html: author ? `${author} &mdash; ${title}` : title,
                    }}
                  />
                </AniLink>
              );
            }
          )}
        </NukaCarousel>
      </div>
    </div>
  );
};

export default ProgramCarousel;
